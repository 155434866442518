import React, { useContext, useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { Spinner } from "react-bootstrap";
import { fetchConsultations, fetchInitiations, fetchRecommendations } from "../http/informationProcessAPI";
import { INITIATION_ROUTE, PUBLIC_CONSULTATIONS_ROUTE, RECOMENDATIONS_ROUTE, DRAFT_DECISIONS_ROUTE } from "../utils/consts";
import { Link } from "react-router-dom";
import { fetchDraftDecisions } from "../http/navLinksAPI";

const DecisionProcess = () => {
	const { informationProcess } = useContext(Context);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchConsultations()
			.then((data) => {
				informationProcess.setConsultation(data);
			})
			.then(() =>
				fetchInitiations()
					.then((data) => {
						informationProcess.setInitiation(data);
					})
					.then(() =>
						fetchRecommendations()
							.then((data) => {
								informationProcess.setRecommendations(data);
							})
							.then(() =>
								fetchDraftDecisions()
									.then((data) => {
										informationProcess.setDraftDecisions(data);
									})
									.finally(() => setLoading(false))
							)
					)
			);
	}, [informationProcess]);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-md-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-5 py-4 col-md-8'>
				<div className='block-title pb-4'>
					<span>Procesul decizional</span>
				</div>
				<Tabs
					defaultActiveKey='consultation'
					id='uncontrolled-tab-example'
					className='d-flex align-items-center justify-content-center mb-3 text-primary fw-bold'>
					<Tab
						eventKey='consultation'
						title='Consultare publică'>
						{informationProcess.consultation.count > 0
							? informationProcess.consultation.rows.map((item, idx) => (
									<Link
										key={"consultation" + idx}
										className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
										to={PUBLIC_CONSULTATIONS_ROUTE + "/" + item.id}>
										<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
										<div className='d-flex justify-content-start py-2'>
											<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab
						eventKey='initiation'
						title='Inițiere proiect'>
						{informationProcess.initiation.count > 0
							? informationProcess.initiation.rows.map((item, idx) => (
									<Link
										key={"initiation" + idx}
										className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
										to={INITIATION_ROUTE + "/" + item.id}>
										<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
										<div className='d-flex justify-content-start py-2'>
											<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab
						eventKey='draftDecisions'
						title='Proiecte de Decizii'>
						{informationProcess.draftDecisions.count > 0
							? informationProcess.draftDecisions.rows.map((item, idx) => (
									<Link
										key={"draft_decision" + idx}
										className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
										to={DRAFT_DECISIONS_ROUTE + "/" + item.id}>
										<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
										<div className='d-flex justify-content-start py-2'>
											<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab
						eventKey='recommendations'
						title='Sinteza recomandărilor'>
						{informationProcess.recommendations.count > 0
							? informationProcess.recommendations.rows.map((item, idx) => (
									<Link
										key={"recommendations" + idx}
										className='d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0'
										to={RECOMENDATIONS_ROUTE + "/" + item.id}>
										<span className='fw-bold font-2 text-primary text-justify truncate'>{item.title}</span>
										<div className='d-flex justify-content-start py-2'>
											<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
				</Tabs>
			</div>
			<Calendar />
		</div>
	);
};

export default DecisionProcess;
