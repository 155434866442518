import { useState, useEffect, useRef } from "react";
import Calendar from "../../components/Calendar";
import { Image, Carousel } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import ShareButton from "../../components/UI/ShareButton";
import { fetchOneSuccessStories, fetchSuccessStories } from "../../http/resourcesServicesAPI";

const SuccessStoriesPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchOneSuccessStories(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4'>
				{data ? (
					<div
						className='d-flex flex-column col-xl-8 col-12 px-xl-4 px-md-4 px-1'
						ref={printRef}>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center'>
							<Image
								style={{
									maxHeight: 200,
									aspectRatio: 1,
									height: "auto",
									objectFit: "contain",
								}}
								src={process.env.REACT_APP_API_URL + data.img}
								className='pe-xl-5 my-3'
							/>
							{data.description ? (
								<div className='text-justify font-2 pt-2 px-3 px-md-0'>{parse(data.description)}</div>
							) : null}
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-xl-5 px-3'>
							{data.author ? (
								<span className='border border-primary rounded text-primary my-sm-2 p-2 fw-bold'>Autor: {data.author}</span>
							) : null}
							{data.date ? <span className='border border-primary rounded text-primary p-2 fw-bold'>{data.date}</span> : null}
							<div className='d-flex flex-row align-items-center justify-content-end'>
								<i
									role='button'
									onClick={() => handlePrint()}
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default SuccessStoriesPage;
